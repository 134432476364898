import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Input, Form, message, Card, Button, Icon, Select, Table, Typography, Checkbox } from 'antd'
import * as firebase from "firebase/app";
import "firebase/firestore";
import FlexSearch from 'flexsearch';
import 'antd/dist/antd.css'


var firebaseConfig = {
	apiKey: "AIzaSyACh7DVqodTu_vTi3EKM7YIVFx8UAzwLRs",
	authDomain: "tofasevent.firebaseapp.com",
	databaseURL: "https://tofasevent.firebaseio.com",
	projectId: "tofasevent",
	storageBucket: "tofasevent.appspot.com",
	messagingSenderId: "619225926220",
	appId: "1:619225926220:web:0ee7a66d8fc218df6f42e9",
	measurementId: "G-9DR277DNLG"
};

FlexSearch.registerMatcher({
	"ü": "u", "ş": "s", "ı": "i", "ö":"o", "ğ":"g", "ü":"u"
})
export default () => {
	let [db, setDB] = useState(null)
	let [users, setUsers] = useState([])
	let [app, setApp] = useState(null)
	
	let [search, setSearch] = useState("")

	let fx = useMemo(() => {
		let f = new FlexSearch("match", {
			doc: {
        id: "id",
        field: ["xname", "tc_no"]
    }
		})
		f.add(users.map(x => ({...x, xname: x.name.toLocaleLowerCase("tr")})))
		return f
	}, [users])

	useEffect(() => {
		if (app == null || db == null) return
		let users = []
		db.collection("Users").onSnapshot(usersSnap => {
			usersSnap.docChanges().forEach(change => {
				if (change.type === "added") {
					let user = change.doc.data()
					setUsers(users => [user, ...users])
				}
				if (change.type === "modified") {
					setUsers(users => {
						let _user = change.doc.data()
						
						return users.map(user => user.id === _user.id ? _user : user)
					})
				}
				if (change.type === "removed") {
					setUsers(users => {
						let _user = change.doc.data()
						
						return users.filter(user => user.id != _user.id)
					})
				}
			})
		})
	}, [app])

	useEffect(() => {
		if (app == null) {
			let app = firebase.initializeApp(firebaseConfig)
			let db = firebase.firestore()
			setApp(app)
			setDB(db)
		}
	}, [])

	let giveThsirt = async (user) => {
		if (db) {
			db.collection("Users").doc(user.name + user.id).set({
				...user,
				status: true
			})
		}
	}

	let remove = async (user) => {
		if(db){
			db.collection("Users").doc(user.name + user.id).delete().catch(console.log)
		}
	}

	let listItems = useMemo(() => {
		
		if(search === "") { 
			let sorted = users.sort(( a, b ) => {
				if ( a.joinDate < b.joinDate ){
					return 1;
				}
				if ( a.joinDate > b.joinDate ){
					return -1;
				}
				return 0;
			})
			return sorted
		}
		else {
		let result = fx.search(search.toLocaleLowerCase("tr"))
		return result
		}
	}, [users, search])

	let download = async () => {
		let headers = "Id; Adı; Firma; Etkinlik Adı; Okutma Tarihi; Ünvan; TC.No.; Beden; Durum"
		let rows = users.map(({id, name, company, event_name, joinDate, position, tc_no, tshirt_size, status}) => {
			return `${id}; ${name}; ${company}; ${event_name}; ${joinDate}; ${position}; ${tc_no}; ${tshirt_size}; ${status ? 'verildi': 'verilmedi'}`
		})
		let csv = headers + '\n' + rows.join('\n')
		csv = csv.replace(/\r\n/g, '' )
		var _url = window.URL.createObjectURL(new Blob(['\ufeff', csv], {encoding:"UTF-8",type:"text/plain;charset=UTF-8"}));
		var a = document.createElement('a');
		a.href = _url;
		a.download = `qr_report.csv`;
		document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
		a.click();    
		a.remove();
	}

	return <div style={{ padding: 20 }}>
		<Card title='Giriş Yapan Kullanıcı Listesi'>
			<div style={{display: 'flex'}}>
			<Input style={{width: 200, margin: 5}} placeholder="Arama" 
				value={search} onChange={s => setSearch(s.target.value)}></Input>

					<Typography.Text style={{marginLeft: 10}}>Verilen: {users.filter(x => x.status).length}</Typography.Text>
					<Typography.Text type='danger' style={{marginLeft: 10}}>Verilmeyen: {users.filter(x => !x.status).length}</Typography.Text>
					<Button onClick={download}>Excel'e Aktar</Button>
			</div>
			<Table dataSource={listItems} rowKey="id" pagination={{ pageSize: 15 }}>
				<Table.Column dataIndex="name" title="Misafir Adı" />
				<Table.Column dataIndex="company" title="Firma" />
				<Table.Column dataIndex="event_name" title="Etkinlik Adı" />
				<Table.Column dataIndex="joinDate" title="Okutma Tarihi" />
				<Table.Column dataIndex="position" title="Ünvan" />
				<Table.Column dataIndex="tc_no" title="T.C. No" />
				<Table.Column dataIndex="tshirt_size" title="Beden" />
				<Table.Column dataIndex="status" title="Tişört Durumu" render={(v) => <> 
					<Icon type="skin" style={{color:v?'#4dcc45':'red', backgroundColor: '#96929224', 
						borderRadius: 27, padding: 10, fontSize: 27 }} />
					<Typography.Text> {v ? 'Verildi' : 'Verilmedi'}</Typography.Text></>} />
				<Table.Column dataIndex="id" render={(v, record) => <>
					{!record.status && <Button onClick={() => giveThsirt(record)}>Tişört Ver</Button>}
				</>} />
				<Table.Column dataIndex="id" title="Sil" render={(v, record) => <>
					<Button type='danger' onClick={() => remove(record)}>Sil</Button>
				</>} />
			</Table>
		</Card>
	</div>
	// return <div style={{ backgroundColor: '#1fc8db', backgroundImage: 'linear-gradient(141deg, #9fb8ad 20%, #1fc8db 51%, #2cb5e8 70%)', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
	// 	<Card title='MİSAFİR BİLGİLERİ' bordered={true} style={{ width: 500, boxShadow: '5px 4px 20px 5px #93999f', borderRadius: 10 }}>
	// 		<Form>
	// 			<Form.Item>
	// 				<Input size='large' placeholder='Adı'
	// 					onChange={e => SetUser({ ...user, name: e.target.value })} />
	// 			</Form.Item>

	// 			<Form.Item>
	// 				<Input size='large' placeholder='T.C. No'
	// 					onChange={e => SetUser({ ...user, tcNo: e.target.value })} />
	// 			</Form.Item>

	// 			<Form.Item>
	// 				<Select size='large' placeholder='Beden'
	// 					onChange={v => SetUser({ ...user, size: v })} >
	// 					<Select.Option value='xs'>XS</Select.Option>
	// 					<Select.Option value='s'>S</Select.Option>
	// 					<Select.Option value='m'>M</Select.Option>
	// 					<Select.Option value='l'>L</Select.Option>
	// 					<Select.Option value='xl'>XL</Select.Option>
	// 					<Select.Option value='xxl'>XXL</Select.Option>
	// 				</Select>
	// 			</Form.Item>

	// 			<Form.Item>
	// 				<Button type="primary" onClick={() => message.success('Teslimat yapıldı.')} style={{ width: '100%' }}>
	// 					<Icon type="plus" /> Teslim Et!
	// 				</Button>
	// 			</Form.Item>
	// 		</Form>
	// 	</Card>
	// </div>
}